.Flow {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px);
    box-sizing: border-box;   
}

.Flow-leadstatus:not(:last-child)
{
    margin-bottom: 12px;
}

.Flow-leadstatus {
    padding: 24px;
}

.Flow-title {
    padding-left: 6px;
}

.Flow-horz {
    display: flex;
    flex-direction: row;
    font-size: 8pt;
    align-items: center;
}

.Flow-vert {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 8pt;
}

.Flow-vert .Flow-new-action {
    visibility: hidden;
}

.Flow-vert:hover .Flow-new-action {
    visibility: visible;
}

.Flow-vert .Flow-new-event {
    visibility: hidden;
}

.Flow-vert:hover .Flow-new-event {
    visibility: visible;
}

.Flow-item {
    position: relative;
    padding: 6px;
    width: 120px;
    min-height: 40px;

    align-self: stretch;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: perspective(6px);
    margin-bottom: 10px;

    font-size: 8pt;

    cursor: pointer;
}

.Flow-event {
    border: 1px solid #21c396;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: linear-gradient(#ffffff, rgba(33, 195, 150, 0.667));
    color: black;    
}

.Flow-system-event {
    border: 1px solid gray;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: linear-gradient(#ffffff, rgba(150, 150, 150, 0.667));
    color: black;    
}

.Flow-user-trigger {
    border: 1px solid #c32196;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: linear-gradient(#ffffff, rgba(195, 33 , 150, 0.667));
    color: black;    
}

.Flow-status-transition {
    border: 1px solid #3dc04e;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: linear-gradient(#ffffff, rgba(61, 192, 78, 0.667));
    color: black;    
}

.Flow-circular-event {
    border: 2px dashed red;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: #ff7777;
    color: black;    
}

.Flow-action {
    border: 1px solid #2196f3;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: linear-gradient(#ffffff, rgba(33, 150, 243, 0.667));
    color: black;    
}

.Flow-action2 {
    border: 1px dashed rgba(33, 150, 243);
    color: black;
}

.Flow-new-event {
    border: 1px dashed #cccccc;
    color: white;    
    color: transparent;    
    cursor: pointer;
}

.Flow-new-event:hover {
    border: 1px dashed #777777;
    color: #777777;
}

.Flow-new-action {
    border: 1px dashed #cccccc;
    color: white;    
    cursor: pointer;
}

.Flow-new-action:hover {
    border: 1px dashed #777777;
    color: #777777;
}

.Flow-vert .Flow-action-add{
    visibility: hidden;
}

.Flow-vert:hover .Flow-action-add{
    visibility: visible;
}

.Flow-action-add {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 40px;
    height: 20px;
    border: 1px solid black;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px 15px 15px 15px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.LeadStatus .AddEvent {
    visibility: hidden;
}

.LeadStatus:hover .AddEvent {
    visibility: visible;
}