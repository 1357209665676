.CalendarPage_allDay {
    border: 1px solid #c37700;
    background: -webkit-gradient(linear,right top, left top,color-stop(70%, rgba(195, 119, 0, 0.063)), to(rgba(195, 119, 0, 0.267)));
    background: linear-gradient(to left,rgba(195, 119, 0, 0.063) 70%, rgba(195, 119, 0, 0.267));
    color: #c37700;    
    margin-left: 0px;
    height: calc(100% + 30px);
    text-align: right;
    padding: 5px;
    padding-right: 10px;
    padding-top: 35px;
    font-feature-settings: "c2sc";
    font-variant-caps: small-caps;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    border-radius: 5px;
}

/* availabilit */
.FullCalendar_available {
    border: 1px solid #21c396;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(33, 195, 150, 0.667)));
    background: linear-gradient(#ffffff, rgba(33, 195, 150, 0.667));
    color: #21c396;    
}

/** pi appt **/
.FullCalendar_appt {
    /* unselected */
    border: 1px solid #2196f3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(33, 150, 243, 0.667)));
    background: linear-gradient(#ffffff, rgba(33, 150, 243, 0.667));
    color: #2196f3;    
}

/* other o365 event */
.FullCalendar_notpi {
    border: 2px dotted #216bf3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgb(33, 50, 146, 0.5)));
    background: linear-gradient(#ffffff, rgb(33, 50, 146, 0.5));
    color: #216bf3;    
}

/* not busy */
.FullCalendar_notbusy {
    border: 1px dotted #444444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(68, 68, 68, 0.5)));
    background: linear-gradient(#ffffff, rgba(68, 68, 68, 0.5));
    color: #444444;    
}

.FullCalendar_loadMore {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    background: -webkit-gradient(linear, left top, left bottom, from(#ccccff), color-stop(#ffffff), to(#ccccff));
    background: linear-gradient(#ccccff, #ffffff, #ccccff);
    border: 1px solid #7777ff;
    color: #7777ff;
    border-radius: 10px;
    margin-left: 10px;
    -webkit-text-orientation: sideways;
            text-orientation: sideways;
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
}

.FullCalendar_loadMore:hover {
    border: 1px solid #7777ff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);   
}
.ContentPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContentPage_body {
    overflow: auto;
    height: calc(100 * var(--vh) - 56px - 24px);
    padding: 6px;
}

.ContentPage_scrollable {
    overflow: auto;
    height: calc(100 * var(--vh) - 56px);
    padding: 6px;
}

.ContentPage_card {
    padding: 12px;
}

@media screen and (min-width: 400px) {
    .ContentPage {
        padding: 12px;
    }
    .ContentPage_body {
        padding: 12px;
    }
    .ContentPage_scrollable {
        padding: 12px;
    }
}

@media screen and (min-width: 600px) {
    .ContentPage {
        padding: 24px;
    }    
    .ContentPage_body {
        height: calc(100 * var(--vh) - 64px - 48px);
    }
    .ContentPage_scrollable {
        height: calc(100 * var(--vh) - 64px);
        padding: 24px;
    }
    .ContentPage_card {
        padding: 24px;
        max-width: 600px;
        min-width: 400px;
    }    
}
.Welcome_login {
    padding: 12px;
    margin: 12px;
    border-radius: 12px;
    background-color: #efefef;
    text-align: center;
    box-sizing: border-box;
}

.Welcome_login BUTTON {
    margin: 6px;
}

@media screen and (min-width: 600px) {
    .Welcome_login {
        padding: 24px;
        margin: 24px;
    }
}
.EnableSync {
    padding: 24px;
}

.EnableSync BUTTON {
    margin-right: 6px;
}

.InitialWizard_footer {
    margin-top: 12px;
}
.InitialWizard_footer BUTTON:nth-of-type(2) {
    margin-left: 12px;
}

.Profile {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px);
    box-sizing: border-box;
}

.Profile .Profile_main {
    padding: 24px;
}

.Profile .Profile_userIdentities {
    margin-top: 24px;
}

.Profile .Profile_userIdentities .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px);
}
.Integration {
    margin-top: 12px;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
}

.Integration:hover {
    background-color: #efefef;
    cursor: pointer;
}

.Integration_checkbox {
    width: 50px;
}

.Integration_icon {
    width: 50px;
}
.TabPage {
    padding: 6px;
}

.TabPage_tabs {
    padding: 6px;
}

.TabPage_detail {
    padding: 6px;
    padding-top: 0px;
}

.TabPage_grid {
    padding: 6px;
    padding-top: 0px;
}

.TabPage_scrollable {
    padding: 6px;
    padding-top: 0px;
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px);
    overflow: auto;
}

.TabPage_detail .ReadOnlyForm {
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px);
    overflow: auto;
}

.TabPage_grid .DataGrid {
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px);
    overflow: auto;
}

@media screen and (min-width: 600px) {
    .TabPage {
        padding: 18px;
    }

    .TabPage_detail .ReadOnlyForm {
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px);
    }
        
    .TabPage_grid .DataGrid {
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px);
    }    

    .TabPage_scrollable {
        padding: 6px;
        padding-top: 0px;
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px);
        overflow: auto;
    }    
}

.Fields {
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;  
    font-size: 0.8125rem;
    font-weight: 400;
    overflow: auto;
}

.Fields TABLE {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
}

.Fields TABLE .header TD {
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    padding: 4px;
    text-align: center;
}

.Fields_field TD {
    border: 1px solid #cccccc;
    padding: 4px;
}

.Field {
    border: 1px solid red;
}

.Field_editable {
    cursor: pointer;
}

.Field_editable INPUT {
    width: 100%;
    margin: 0px;
}

.Field_checkbox {
    cursor: pointer;
    text-align: center;
}

.Fields_section TD {
    padding: 4px;
    font-size: 10pt;
    padding-left: 4px;
    background-color: aliceblue;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
.PopupDialog_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.AppointmentDialog .DialogContent .Calendar {
    border: 2px solid #cccccc;
    background-color: #efefef;
}

.AppointmentDialog_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.CalendarDialog_dropDown {
    display: flex;
    height: 60px;
}

.CalendarDialog_dropDown .DropDown {
    margin-right: 0px;
}

.CalendarDialog_dropDown .DropDown:first-child {
    margin-right: 12px;
}

.AppointmentDialog .DialogContent .Footer {
    margin-top: 12px;
}

.LeadTabPage_grid {
    padding: 6px;
    padding-top: 0px;
}

.LeadTabPage_grid .DataGrid {
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px - 48px);
    overflow: auto;
}

.LeadTabPage_grid .Footer {
    margin-top: 12px;
}

@media screen and (min-width: 600px) {
    .AppointmentDialog .DialogContent .Calendar {
        height: calc(100 * var(--vh) - 156px - 60px);
        border: 2px solid #cccccc;
        background-color: #efefef;
    }
    
    .AppointmentDialog .DialogContent .Footer {
        margin-top: 24px;
    }

    .LeadTabPage_grid .DataGrid {
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px - 60px);
    }    

    .LeadTabPage_grid .Footer {
        margin-top: 24px;
    }    

    .CalendarDialog_dropDown .DropDown:first-child {
        margin-right: 24px;
    }    
}

.LeadIntegrations {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 24px 12px 24px;
    box-sizing: border-box;
}

.LeadIntegrations_clickable {
    cursor: pointer;
}

div.LeadIntegrations:not(:first-child) {
    border-top: 1px solid #eeeeee;
    padding: 12px 24px;
}

.LeadIntegrations_icon {
    padding-right: 10px;
}

.LeadAppointments {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 24px 12px 24px;
    box-sizing: border-box;
}

div.LeadAppointments:not(:first-child) {
    border-top: 1px solid #eeeeee;
    padding: 12px 24px;
}

.LeadAppointments_footer {
    padding: 12px 24px 24px 24px;
}

.LeadAppointments_horz {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
}

.LeadAppointments_appointments
{
    overflow-y: auto;
    max-height: 300px;
}

.LeadAppointments_notes {
    background-color: #cccccc44;
    padding: 20px;
    width: 100%;
}

.LeadAppointments_clickable {
    cursor: pointer;
}

.LeadHistory {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;   
    padding: 16px 24px;
    cursor: pointer;
}

.LeadHistory_body {
    overflow: auto;
    width: 100%;
}

.LeadHistory_body table {
    width: 100%;
}

.LeadHistory_row {
    box-sizing: border-box;   
    padding: 0px 0px 16px 0px;
}

.LeadHistory_row:not(:first-child) {
    padding: 12px 0px;
}

.LeadHistory_row:not(:first-child) td {
    border-top: 1px solid #eeeeee;
}

.LeadHistory_row td {
    padding: 6px;
}

.LeadHistory_td_left {
    text-align: left;
}

.LeadHistory_td_center {
    text-align: center;
}

.LeadHistory_icon {
    width: 40px;
    text-align: center;
}

.LeadHistory_icon img {
    width: 24px;
    height: 24px;
}
.DashboardBarChart {
    width: 100%;
    padding: 12px;
    text-align: center;
}

.DashboardBarChart_graph {
    width: 100%;
}

.DashboardBarChart_title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.DashboardBarChart_title svg {
    cursor: pointer;
    padding-left: 4px;
}
.Flow {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px);
    box-sizing: border-box;   
}

.Flow-leadstatus:not(:last-child)
{
    margin-bottom: 12px;
}

.Flow-leadstatus {
    padding: 24px;
}

.Flow-title {
    padding-left: 6px;
}

.Flow-horz {
    display: flex;
    flex-direction: row;
    font-size: 8pt;
    align-items: center;
}

.Flow-vert {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 8pt;
}

.Flow-vert .Flow-new-action {
    visibility: hidden;
}

.Flow-vert:hover .Flow-new-action {
    visibility: visible;
}

.Flow-vert .Flow-new-event {
    visibility: hidden;
}

.Flow-vert:hover .Flow-new-event {
    visibility: visible;
}

.Flow-item {
    position: relative;
    padding: 6px;
    width: 120px;
    min-height: 40px;

    align-self: stretch;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transform: perspective(6px);

            transform: perspective(6px);
    margin-bottom: 10px;

    font-size: 8pt;

    cursor: pointer;
}

.Flow-event {
    border: 1px solid #21c396;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(33, 195, 150, 0.667)));
    background: linear-gradient(#ffffff, rgba(33, 195, 150, 0.667));
    color: black;    
}

.Flow-system-event {
    border: 1px solid gray;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(150, 150, 150, 0.667)));
    background: linear-gradient(#ffffff, rgba(150, 150, 150, 0.667));
    color: black;    
}

.Flow-user-trigger {
    border: 1px solid #c32196;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(195, 33 , 150, 0.667)));
    background: linear-gradient(#ffffff, rgba(195, 33 , 150, 0.667));
    color: black;    
}

.Flow-status-transition {
    border: 1px solid #3dc04e;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(61, 192, 78, 0.667)));
    background: linear-gradient(#ffffff, rgba(61, 192, 78, 0.667));
    color: black;    
}

.Flow-circular-event {
    border: 2px dashed red;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: #ff7777;
    color: black;    
}

.Flow-action {
    border: 1px solid #2196f3;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .2);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(33, 150, 243, 0.667)));
    background: linear-gradient(#ffffff, rgba(33, 150, 243, 0.667));
    color: black;    
}

.Flow-action2 {
    border: 1px dashed rgba(33, 150, 243);
    color: black;
}

.Flow-new-event {
    border: 1px dashed #cccccc;
    color: white;    
    color: transparent;    
    cursor: pointer;
}

.Flow-new-event:hover {
    border: 1px dashed #777777;
    color: #777777;
}

.Flow-new-action {
    border: 1px dashed #cccccc;
    color: white;    
    cursor: pointer;
}

.Flow-new-action:hover {
    border: 1px dashed #777777;
    color: #777777;
}

.Flow-vert .Flow-action-add{
    visibility: hidden;
}

.Flow-vert:hover .Flow-action-add{
    visibility: visible;
}

.Flow-action-add {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 40px;
    height: 20px;
    border: 1px solid black;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px 15px 15px 15px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.LeadStatus .AddEvent {
    visibility: hidden;
}

.LeadStatus:hover .AddEvent {
    visibility: visible;
}
.AppConfig {
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: auto;
}

.AppConfig_form {
    margin-top: 16px;
    margin-bottom: 16px;
}

.AppConfig_editor {
    width: 100%;
    height: 100%;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12pt;
}

.Agenda {
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* align-items: stretch; */
    /* align-content: space-between; */
}

.Agenda_week {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
}

.Agenda_sunday {
    background: -webkit-gradient(linear, right top, left top, color-stop(90%, white), to(#ff9100));
    background: linear-gradient(270deg, white 90%, #ff9100);
}

.Agenda_row {
    display: flex;
    flex-direction: row;
}

.Agenda_day {
    vertical-align: top;
    margin: 6px; 
    /* border: 1px solid red; */
    display: inline-table;
    /* padding: 6px; */
}

.Agenda_user {
    text-align: center;
    max-width: 200px;
    margin: 3px;
}

.Agenda_empty {
    border: 1px solid #efefef;
}

.Agenda_cancelled {
    text-decoration: line-through;
    color: #777777;
}

.Agenda_userheader {
    text-align: center;
}

.Agenda_appointment {
    border: 1px solid #efefef;
    background-color: #efefef;
    text-align: left;
    padding: 6px;
    border-radius: 6px;
    margin-top: 3px;
    margin-bottom: 3px;

    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
}

.Agenda_time {
    font-size: 8pt;
    display: inline;
    padding: 3px;
    text-align: right;
}

.Agenda_subject {
    font-size: 10pt;
    display: inline;
    vertical-align: middle;
    padding: 6px;
}

@media screen and (min-width: 600px) {
    .Agenda_week {
        justify-content: left; 
    }
    
    .Agenda_user {
        width: 200px;
    }
}
.User {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px);
    box-sizing: border-box;
}

.User .User_main {
    padding: 24px;
}

/* .User .Profile_userIdentities {
    margin-top: 24px;
}

.Profile .Profile_userIdentities .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px);
} */
.Organization {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px - 24px);
    box-sizing: border-box;
}

.Organization .Organization_main {
    padding: 24px;
}

.Organization .Organization_users .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px - 24px);
}

.Organization_salesforce {
    padding: 6px;
    color: red;
    border: 2px dashed red;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
}

.MetaData .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px - 64px - 24px);
}

.MetaData .Actions {
    margin-top: 12px;
}

.MetaData .Actions :not(:first-child){
    margin-left: 12px;
}

:root{
    --vh: 1vh;
}

* {
    box-sizing: border-box;
    --vh: inherit;
}

.body {
    font-family: 'Roboto', sans-serif;  
    position: fixed;
    margin: 0;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    background-color: #eeeeee;
    overflow: none;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background: rgba(0,0,0,.07);
}

.piPanel_title {
    padding: 16px 24px;
}

.BottomActionsBar button:not(:first-child) {
    margin-left: 12px;
}

.truncate-text {
	overflow: hidden;
	text-overflow: ellipsis;
    display: block;
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 600px) {
    .body {
        display: flex;              /* establish flex container */
        flex-direction: column;     /* stack flex items vertically */
        justify-content: center;    /* center items vertically, in this case */      
        align-items: center;        /* center items horizontally, in this case */              
    }
}

/* .progress {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #77777777;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}  */
