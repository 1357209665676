.Welcome_login {
    padding: 12px;
    margin: 12px;
    border-radius: 12px;
    background-color: #efefef;
    text-align: center;
    box-sizing: border-box;
}

.Welcome_login BUTTON {
    margin: 6px;
}

@media screen and (min-width: 600px) {
    .Welcome_login {
        padding: 24px;
        margin: 24px;
    }
}