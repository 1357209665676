.ContentPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContentPage_body {
    overflow: auto;
    height: calc(100 * var(--vh) - 56px - 24px);
    padding: 6px;
}

.ContentPage_scrollable {
    overflow: auto;
    height: calc(100 * var(--vh) - 56px);
    padding: 6px;
}

.ContentPage_card {
    padding: 12px;
}

@media screen and (min-width: 400px) {
    .ContentPage {
        padding: 12px;
    }
    .ContentPage_body {
        padding: 12px;
    }
    .ContentPage_scrollable {
        padding: 12px;
    }
}

@media screen and (min-width: 600px) {
    .ContentPage {
        padding: 24px;
    }    
    .ContentPage_body {
        height: calc(100 * var(--vh) - 64px - 48px);
    }
    .ContentPage_scrollable {
        height: calc(100 * var(--vh) - 64px);
        padding: 24px;
    }
    .ContentPage_card {
        padding: 24px;
        max-width: 600px;
        min-width: 400px;
    }    
}