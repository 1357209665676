.CalendarPage_allDay {
    border: 1px solid #c37700;
    background: linear-gradient(to left,rgba(195, 119, 0, 0.063) 70%, rgba(195, 119, 0, 0.267));
    color: #c37700;    
    margin-left: 0px;
    height: calc(100% + 30px);
    text-align: right;
    padding: 5px;
    padding-right: 10px;
    padding-top: 35px;
    font-variant-caps: small-caps;
    transform: translateY(-30px);
    border-radius: 5px;
}
