:root{
    --vh: 1vh;
}

* {
    box-sizing: border-box;
    --vh: inherit;
}

.body {
    font-family: 'Roboto', sans-serif;  
    position: fixed;
    margin: 0;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    background-color: #eeeeee;
    overflow: none;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background: rgba(0,0,0,.07);
}

.piPanel_title {
    padding: 16px 24px;
}

.BottomActionsBar button:not(:first-child) {
    margin-left: 12px;
}

.truncate-text {
	overflow: hidden;
	text-overflow: ellipsis;
    display: block;
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 600px) {
    .body {
        display: flex;              /* establish flex container */
        flex-direction: column;     /* stack flex items vertically */
        justify-content: center;    /* center items vertically, in this case */      
        align-items: center;        /* center items horizontally, in this case */              
    }
}

/* .progress {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #77777777;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}  */