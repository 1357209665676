.Integration {
    margin-top: 12px;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
}

.Integration:hover {
    background-color: #efefef;
    cursor: pointer;
}

.Integration_checkbox {
    width: 50px;
}

.Integration_icon {
    width: 50px;
}