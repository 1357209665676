.DashboardBarChart {
    width: 100%;
    padding: 12px;
    text-align: center;
}

.DashboardBarChart_graph {
    width: 100%;
}

.DashboardBarChart_title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.DashboardBarChart_title svg {
    cursor: pointer;
    padding-left: 4px;
}