/* availabilit */
.FullCalendar_available {
    border: 1px solid #21c396;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(#ffffff, rgba(33, 195, 150, 0.667));
    color: #21c396;    
}

/** pi appt **/
.FullCalendar_appt {
    /* unselected */
    border: 1px solid #2196f3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(#ffffff, rgba(33, 150, 243, 0.667));
    color: #2196f3;    
}

/* other o365 event */
.FullCalendar_notpi {
    border: 2px dotted #216bf3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(#ffffff, rgb(33, 50, 146, 0.5));
    color: #216bf3;    
}

/* not busy */
.FullCalendar_notbusy {
    border: 1px dotted #444444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(#ffffff, rgba(68, 68, 68, 0.5));
    color: #444444;    
}

.FullCalendar_loadMore {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    background: linear-gradient(#ccccff, #ffffff, #ccccff);
    border: 1px solid #7777ff;
    color: #7777ff;
    border-radius: 10px;
    margin-left: 10px;
    text-orientation: sideways;
    writing-mode: vertical-lr;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
}

.FullCalendar_loadMore:hover {
    border: 1px solid #7777ff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);   
}