.Fields {
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;  
    font-size: 0.8125rem;
    font-weight: 400;
    overflow: auto;
}

.Fields TABLE {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
}

.Fields TABLE .header TD {
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    padding: 4px;
    text-align: center;
}

.Fields_field TD {
    border: 1px solid #cccccc;
    padding: 4px;
}

.Field {
    border: 1px solid red;
}

.Field_editable {
    cursor: pointer;
}

.Field_editable INPUT {
    width: 100%;
    margin: 0px;
}

.Field_checkbox {
    cursor: pointer;
    text-align: center;
}

.Fields_section TD {
    padding: 4px;
    font-size: 10pt;
    padding-left: 4px;
    background-color: aliceblue;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}