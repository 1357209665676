.Organization {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px - 24px);
    box-sizing: border-box;
}

.Organization .Organization_main {
    padding: 24px;
}

.Organization .Organization_users .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px - 24px);
}

.Organization_salesforce {
    padding: 6px;
    color: red;
    border: 2px dashed red;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
}

.MetaData .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px - 64px - 24px);
}

.MetaData .Actions {
    margin-top: 12px;
}

.MetaData .Actions :not(:first-child){
    margin-left: 12px;
}
