.EnableSync {
    padding: 24px;
}

.EnableSync BUTTON {
    margin-right: 6px;
}

.InitialWizard_footer {
    margin-top: 12px;
}
.InitialWizard_footer BUTTON:nth-of-type(2) {
    margin-left: 12px;
}
