.AppointmentDialog .DialogContent .Calendar {
    border: 2px solid #cccccc;
    background-color: #efefef;
}

.AppointmentDialog_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.CalendarDialog_dropDown {
    display: flex;
    height: 60px;
}

.CalendarDialog_dropDown .DropDown {
    margin-right: 0px;
}

.CalendarDialog_dropDown .DropDown:first-child {
    margin-right: 12px;
}

.AppointmentDialog .DialogContent .Footer {
    margin-top: 12px;
}

.LeadTabPage_grid {
    padding: 6px;
    padding-top: 0px;
}

.LeadTabPage_grid .DataGrid {
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px - 48px);
    overflow: auto;
}

.LeadTabPage_grid .Footer {
    margin-top: 12px;
}

@media screen and (min-width: 600px) {
    .AppointmentDialog .DialogContent .Calendar {
        height: calc(100 * var(--vh) - 156px - 60px);
        border: 2px solid #cccccc;
        background-color: #efefef;
    }
    
    .AppointmentDialog .DialogContent .Footer {
        margin-top: 24px;
    }

    .LeadTabPage_grid .DataGrid {
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px - 60px);
    }    

    .LeadTabPage_grid .Footer {
        margin-top: 24px;
    }    

    .CalendarDialog_dropDown .DropDown:first-child {
        margin-right: 24px;
    }    
}

.LeadIntegrations {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 24px 12px 24px;
    box-sizing: border-box;
}

.LeadIntegrations_clickable {
    cursor: pointer;
}

div.LeadIntegrations:not(:first-child) {
    border-top: 1px solid #eeeeee;
    padding: 12px 24px;
}

.LeadIntegrations_icon {
    padding-right: 10px;
}

.LeadAppointments {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 24px 12px 24px;
    box-sizing: border-box;
}

div.LeadAppointments:not(:first-child) {
    border-top: 1px solid #eeeeee;
    padding: 12px 24px;
}

.LeadAppointments_footer {
    padding: 12px 24px 24px 24px;
}

.LeadAppointments_horz {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
}

.LeadAppointments_appointments
{
    overflow-y: auto;
    max-height: 300px;
}

.LeadAppointments_notes {
    background-color: #cccccc44;
    padding: 20px;
    width: 100%;
}

.LeadAppointments_clickable {
    cursor: pointer;
}

.LeadHistory {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;   
    padding: 16px 24px;
    cursor: pointer;
}

.LeadHistory_body {
    overflow: auto;
    width: 100%;
}

.LeadHistory_body table {
    width: 100%;
}

.LeadHistory_row {
    box-sizing: border-box;   
    padding: 0px 0px 16px 0px;
}

.LeadHistory_row:not(:first-child) {
    padding: 12px 0px;
}

.LeadHistory_row:not(:first-child) td {
    border-top: 1px solid #eeeeee;
}

.LeadHistory_row td {
    padding: 6px;
}

.LeadHistory_td_left {
    text-align: left;
}

.LeadHistory_td_center {
    text-align: center;
}

.LeadHistory_icon {
    width: 40px;
    text-align: center;
}

.LeadHistory_icon img {
    width: 24px;
    height: 24px;
}