.TabPage {
    padding: 6px;
}

.TabPage_tabs {
    padding: 6px;
}

.TabPage_detail {
    padding: 6px;
    padding-top: 0px;
}

.TabPage_grid {
    padding: 6px;
    padding-top: 0px;
}

.TabPage_scrollable {
    padding: 6px;
    padding-top: 0px;
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px);
    overflow: auto;
}

.TabPage_detail .ReadOnlyForm {
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px);
    overflow: auto;
}

.TabPage_grid .DataGrid {
    max-height: calc(100 * var(--vh) - 56px - 24px - 60px);
    overflow: auto;
}

@media screen and (min-width: 600px) {
    .TabPage {
        padding: 18px;
    }

    .TabPage_detail .ReadOnlyForm {
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px);
    }
        
    .TabPage_grid .DataGrid {
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px);
    }    

    .TabPage_scrollable {
        padding: 6px;
        padding-top: 0px;
        max-height: calc(100 * var(--vh) - 64px - 48px - 60px);
        overflow: auto;
    }    
}
