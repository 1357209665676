.AppConfig {
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: auto;
}

.AppConfig_form {
    margin-top: 16px;
    margin-bottom: 16px;
}

.AppConfig_editor {
    width: 100%;
    height: 100%;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12pt;
}