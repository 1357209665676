.User {
    overflow: auto;
    height: calc(100 * var(--vh) - 64px);
    box-sizing: border-box;
}

.User .User_main {
    padding: 24px;
}

/* .User .Profile_userIdentities {
    margin-top: 24px;
}

.Profile .Profile_userIdentities .DataGrid {
    height: auto;
    max-height: calc(100 * var(--vh) - 18px - 64px);
} */