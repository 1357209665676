.Agenda {
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* align-items: stretch; */
    /* align-content: space-between; */
}

.Agenda_week {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
}

.Agenda_sunday {
    background: linear-gradient(270deg, white 90%, #ff9100);
}

.Agenda_row {
    display: flex;
    flex-direction: row;
}

.Agenda_day {
    vertical-align: top;
    margin: 6px; 
    /* border: 1px solid red; */
    display: inline-table;
    /* padding: 6px; */
}

.Agenda_user {
    text-align: center;
    max-width: 200px;
    margin: 3px;
}

.Agenda_empty {
    border: 1px solid #efefef;
}

.Agenda_cancelled {
    text-decoration: line-through;
    color: #777777;
}

.Agenda_userheader {
    text-align: center;
}

.Agenda_appointment {
    border: 1px solid #efefef;
    background-color: #efefef;
    text-align: left;
    padding: 6px;
    border-radius: 6px;
    margin-top: 3px;
    margin-bottom: 3px;

    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
}

.Agenda_time {
    font-size: 8pt;
    display: inline;
    padding: 3px;
    text-align: right;
}

.Agenda_subject {
    font-size: 10pt;
    display: inline;
    vertical-align: middle;
    padding: 6px;
}

@media screen and (min-width: 600px) {
    .Agenda_week {
        justify-content: left; 
    }
    
    .Agenda_user {
        width: 200px;
    }
}